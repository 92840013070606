import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { BaseLayout } from "./base-layout";

export const Meta: DocPageMeta = {
	category: "Structure",
	title: "Base Layout",
	notepad: "https://hackmd.io/YI-m7lQmRyysvkRI4pQqYQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Base Layout"
			subTitle="Base Layout is the foundational structure for organizing content within the platform, providing a consistent and user-friendly experience."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={BaseLayout} />
				<Header.H2>Composition</Header.H2>
				<InlineMessage variant="warning">
					<Paragraph>
						The component is expected to be updated. See the{" "}
						<a href="https://siteimprove-wgs.atlassian.net/wiki/spaces/FAN/pages/5222858772/Base+layout+Header">
							new design pattern
						</a>
						. Review the new pattern and follow these guidelines for a consistent user experience.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					The Base Layout comprises the following elements, each serving a specific purpose:
				</Paragraph>
				<Header.H4>Header</Header.H4>
				<Ul
					items={[
						<>
							<b>Purpose</b>: Orients the user and facilitates navigation.
						</>,
						<>
							<b>Contents</b>:
							<Ul
								items={[
									<>
										<b>Page Title</b>: Clearly communicates the current page's content, using the
										same label as the corresponding side navigation item. Keep it concise and
										informative (e.g., "Accessibility Overview").
									</>,
									<>
										<b>Contextual Actions</b> (Optional): Include relevant actions like search,
										export, or other context-specific tools. Prioritize the most important actions
										and use progressive disclosure to reveal additional options when needed.
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H4>Navigation</Header.H4>
				<Ul
					items={[
						<>
							<b>Purpose</b>: Guides users through the platform's content and features.
						</>,
						<>
							<b>Types</b>:
							<Ul
								items={[
									<>
										<b>
											<GatsbyLink to="/lab/components/Navigation/Breadcrumbs">
												Breadcrumbs
											</GatsbyLink>
										</b>
										: Always present if the current page is not a top-level item in the Side
										Navigation.
									</>,
									<>
										<b>
											<GatsbyLink to="/lab/components/Navigation/Side navigation">
												Side Navigation
											</GatsbyLink>
										</b>
										: The primary navigation method for complex hierarchies with multiple sections
										and subsections.
									</>,
									<>
										<b>
											{" "}
											<GatsbyLink to="/lab/components/Navigation/Horizontal navigation">
												Horizontal Navigation
											</GatsbyLink>
										</b>
										: A secondary navigation option suitable for simpler hierarchies with fewer
										top-level categories.
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H4>Main content area:</Header.H4>
				<Ul
					items={[
						<>
							<b>Purpose</b>: Houses the page's core content.
						</>,
						<>
							<b>Implementation</b>:
							<Ul
								items={[
									<>
										<b>
											<GatsbyLink to="/lib/components/Structure/Grid and Grid.Section">
												Grid and Grid.Section
											</GatsbyLink>
										</b>
										: Employ the <Code>Grid</Code> component for overall layout, dividing the area
										into rows and columns. Use <Code>Grid.Section</Code> to define distinct content
										sections within the grid.
									</>,
									<>
										<b>
											<GatsbyLink to="/lib/components/Structure/Content.Layout">
												Content.Layout
											</GatsbyLink>
										</b>
										: Utilize <Code>Content</Code> to further structure and organize content within
										each Grid.Section.
									</>,
									<>
										<b>
											<a href="https://siteimprove-wgs.atlassian.net/wiki/spaces/FAN/pages/4299751471">
												Layout Principles
											</a>
										</b>
										: Adhere to established visual design principles, such as hierarchy, alignment,
										and spacing, to create a clear and aesthetically pleasing presentation.
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H2>Examples</Header.H2>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					The default variant includes breadcrumbs for navigation hierarchy and a content container
					typically used to display a card, paragraph or a block of text. This variant is suitable
					for pages with clear hierarchical structures and a primary focus on textual content.
				</Paragraph>
				<Example fn={DefaultUsage} />
				<Header.H3>Usage without breadcrumbs</Header.H3>
				<Paragraph>
					This variant excludes breadcrumbs and is ideal for pages where navigation hierarchy is
					less critical or for platform with alternative navigation methods, e.g{" "}
					<GatsbyLink to="/lab/components/Navigation/Side navigation">Side Navigation</GatsbyLink>.
				</Paragraph>
				<Example fn={UsageWithoutBreadcrumbs} />
				<Header.H3>Usage with page header</Header.H3>
				<Paragraph>
					This variant incorporates a prominent page header, often including a title and content.
					This is beneficial for pages requiring a strong visual emphasis on the page title and
					textual content.
				</Paragraph>
				<Example fn={UsageWithPageHeader} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={BaseLayout}
					initialProps={{
						children:
							"Social Media that have referred visitors to your website. Expand the table for referring social media pages, trend graphs, and entry pages.",
						breadcrumbs: {
							items: [
								{ title: "Siteimprove", url: "https://siteimprove.com/" },
								{ title: "Github", url: "https://github.com/" },
								{ title: "Wikipedia", url: "https://wikipedia.org/" },
							],
							"aria-label": "breadcrumbs",
						},
						id: "content",
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>BaseLayout</Code> when:
					</Paragraph>
					<Ul
						items={[
							"Establishing a consistent visual structure across pages or screens.",
							"Organizing content into distinct, logical sections.",
							"Providing a clear navigational hierarchy, especially with the default variant.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						Typically, <Code>BaseLayout</Code> serves as the main structural container for platform
						screens, wrapping the primary content areas.
					</Paragraph>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>BaseLayout</Code> to existing components for visual
								consistency.
							</>,
							,
							<>Utilize ample white space to create a clean, uncluttered interface.</>,
							<>
								Strive for a balanced distribution of elements within the header and throughout the
								layout.
							</>,
							<>
								Highlight the most critical actions for the current context, minimizing
								distractions.
							</>,
							<>
								Reveal additional options or actions as needed, rather than overwhelming users with
								everything at once.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Content is minimal and doesn't require a structured layout.",
							<>
								A different layout pattern better suits the specific content type. Refer to
								available{" "}
								<a href="https://siteimprove-wgs.atlassian.net/wiki/spaces/FAN/pages/5227905099/UI+pattern">
									Patterns
								</a>{" "}
								for alternatives.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Ul
						items={[
							"Ensure content within the Base Layout is well-structured and easy to scan.",
							<>This component comes with built-in accessibility, no extra work required.</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph> Page Titles</Paragraph>
					<Ul
						items={[
							"Use the same label from the side navigation for consistency.",
							<>Keep them short, informative, and in sentence case (e.g., "Account Settings").</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const DefaultUsage = () => (
	<BaseLayout
		breadcrumbs={{
			items: [
				{ title: "Siteimprove", url: "https://siteimprove.com/" },
				{ title: "Github", url: "https://github.com/" },
				{ title: "Wikipedia" },
			],
			"aria-label": "breadcrumbs",
		}}
		id="content"
	>
		"Social Media that have referred visitors to your website. Expand the table for referring social
		media pages, trend graphs, and entry pages.",
	</BaseLayout>
);

const UsageWithoutBreadcrumbs = () => (
	<BaseLayout id="content">
		"Social Media that have referred visitors to your website. Expand the table for referring social
		media pages, trend graphs, and entry pages.",
	</BaseLayout>
);

const UsageWithPageHeader = () => (
	<BaseLayout
		pageHeaderProps={{
			title: "Data Retention",
		}}
		breadcrumbs={{
			items: [
				{ title: "Siteimprove", url: "https://siteimprove.com/" },
				{ title: "Github", url: "https://github.com/" },
				{ title: "Wikipedia" },
			],
			"aria-label": "breadcrumbs",
		}}
		id="content"
	>
		"Data older than the chosen retention period will be deleted forever and cannot be retrieved."
	</BaseLayout>
);
