import React from "react";
import { cn } from "@siteimprove/fancylib";
import * as scss from "./breadcrumbs.scss";

export type BreadcrumbProps = {
	items: Breadcrumb[];
	"aria-label": string;
	/** If true, the breadcrumb will not have padding */
	noPadding?: boolean;
};

export type Breadcrumb = {
	/* Value rendered as the link text in the breadcrumb */
	title: string;
	/* Value for the link */
	url?: string | null;
	/** Callback for onClick events */
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export function Breadcrumbs(props: BreadcrumbProps): JSX.Element {
	if (!props.items.length) {
		return <></>;
	}
	return (
		<nav aria-label={props["aria-label"]} data-component="breadcrumbs">
			<ol className={cn(scss.breadcrumbs, props.noPadding && scss.noPadding)}>
				{props.items.map(({ title, url, onClick }, index) => {
					if (index === props.items.length - 1 && (url || onClick)) {
						console.warn("The last breadcrumb should not have a 'url' or 'onClick' property");
					}
					return (
						<li
							className={scss.breadcrumb}
							key={index}
							aria-current={index === props.items.length - 1 ? "page" : undefined}
						>
							{index === props.items.length - 1 ? (
								title
							) : url ? (
								<a href={url} onClick={onClick}>
									{title}
								</a>
							) : (
								title
							)}
						</li>
					);
				})}
			</ol>
		</nav>
	);
}
